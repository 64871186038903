import * as React from "react";
import Layout from "../components/Layout";
import SEO from "../components/Seo";
import Service1Container from "../containers/Service1";

interface IProps {}

const Service1 = (props: IProps) => {
  return (
    <Layout>
      <Service1Container />
    </Layout>
  );
};

export default Service1;

export const Head = () => (
  <SEO title={`Хөдөлгөөнт засал`} description={`Хөдөлгөөнт засал эмчилгээ`} />
);
