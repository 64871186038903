import { StaticImage } from "gatsby-plugin-image";
import * as React from "react";
import { BsCheck2 } from "react-icons/bs";
import anime from "../utils/anime";

interface IProps {}

const Service1Container = (props: IProps) => {
  const list = [
    {
      title: "Үйлчилгээгээ сонгох",
      desc: "Гэртээ эсвэл оффис дээрээ хүссэн бариа заслаа сонгож захиалаарай.",
    },
    {
      title: "Захиалгаа хийх",
      desc: "Барианы төрөл, бариачаа сонгоход хүссэн газарт тань ширээтэй очно.",
    },
    {
      title: "Давтан үйлчлүүлэх",
      desc: "Жилийн 365 өдөр өглөөний 8 цагаас орой хүртэл хүссэн үедээ захиалгаа өгч цагийг сайхан өнгөрүүлээрэй.",
    },
  ];

  const priceList = [
    {
      title: "Бүтэн биеийн алжаал тайлах цэгэн бариа ",
      duration: "60 - 90 минут",
      price: "₮110,000 - ₮140,000",
      items: [
        "Зангирсан булчинг суллана",
        "Цусны эргэлтийг сайжруулна",
        "Дархлааны эд эсүүдийг сэргээнэ",
      ],
    },
    {
      title: "Бүтэн биеийн арома тосон бариа",
      duration: "60 - 90 минут",
      price: "₮130,000 - ₮160,000",
      items: [
        "Бодисын солилцоог сайжруулан",
        "Арьсыг чийглэг булбарай болгоно",
        "Арьсыг чийглэг булбарай болгоно",
      ],
    },
    {
      title: "Хүүхдийн дархлааны бариа ",
      duration: "60 минут",
      price: "₮90,000",
      items: [
        "Биеийн тунгалагийн тогтолцоог сайжруулах",
        "Цусны эргэлтийг сайжруулах",
        "Уураг тархины үйл ажиллагааг дэмжинэ",
      ],
    },
    {
      title: "Жирэмсний зөөлөн бариа ",
      duration: "60 - 90 минут",
      price: "₮110,000 - ₮140,000",
      items: [
        "Нурууны өвчнийг намдана",
        "Булчин суллана",
        "Биеийг амрааж өгнө",
      ],
    },
    {
      title: "Хөлийн бариа & хуянгийн бариа",
      duration: "60 - 90 минут",
      price: "₮110,000 - ₮140,000",
      items: [
        "Цэгүүдэд дарж архаг ядаргааг тайлах",
        "Хүзүү, мөрний хөшингө, чилсэн зовиурыг арилгах",
      ],
    },
  ];

  return (
    <div>
      <div className="relative overflow-hidden mb-10 lg:mb-0">
        <div className="mx-auto relative max-w-7xl px-8">
          <div className="absolute hidden lg:block top-0 -right-4/5 lg:right-[2rem] bottom-0">
            <StaticImage
              src="../images/v2/aljaal-tailah-baria-0.png"
              alt={"Urtuu"}
              placeholder="none"
              quality={100}
              layout="fixed"
              height={578}
            />
          </div>
          <div
            className="absolute hidden lg:block top-0 transform translate-x-[20%] left-0 bottom-0 h-[578px] w-full right-0"
            style={{
              background:
                "linear-gradient(90deg, #fff 15.26%, #fff 35.56%, rgba(255, 255, 255, 0) 48.36%)",
            }}
          ></div>
          <div className="flex flex-col md:flex-row lg:min-h-[600px] items-center w-full relative">
            <div className="w-ful flex flex-col justify-center">
              <div className="block lg:hidden">
                <StaticImage
                  src="../images/v2/aljaal-tailah-baria-0.png"
                  alt={"Urtuu"}
                  placeholder="none"
                  quality={100}
                />
              </div>
              <div className="text-left relative flex-col justify-center items-start">
                <span
                  className="font-bold my-5 lg:my-5 text-[21px] lg:text-[38px] uppercase mb-5 block"
                  {...anime("fade-right", 600)}
                >
                  Алжаал тайлах бариа
                </span>
                <p
                  className="text-[18px] lg:text-[24px] text-justify lg:text-left p-0 w-full mb-2 text-[#1E1E1E] lg:w-1/2"
                  {...anime("fade-right", 600, 300)}
                >
                  Бүтэн биеийн алжаал тайлах бариа нь толгойноос хөлийн ул
                  хүртлэх зангирсан булчинг суллах цэгэн бариа ба стресс тайлж,
                  дархлааг дэмжих ач тустай.
                </p>
                {/* <button
                className="h-[85px] text-[21px] px-10 font-bold text-c1 items-center justify-center flex border-[2px] rounded-full border-c1"
                {...anime("fade-right", 600, 600)}
                onClick={() => {}}
              >
                Захиалах
              </button> */}
              </div>
            </div>
          </div>
        </div>
      </div>

     
      <div>
        <div className="mx-auto max-w-7xl px-8">
          <h1 className="text-[21px] lg:text-[38px] font-bold uppercase text-center m-5 mb-10">
            Үйлчилгээ авах дараалал
          </h1>
          <div className="grid grid-cols-1 lg:grid-cols-3 gap-10">
            {list?.map((l, i) => (
              <div
                {...anime("fade-up", 500, 300 * i)}
                key={`f-lis-${i}`}
                className="bg-[#F8F8F8] rounded-[20px] flex flex-col  space-y-4 justify-center items-center p-10"
              >
                <div className="w-[100px] h-[100px] text-5xl font-bold bg-c1 rounded-full flex items-center justify-center text-white">
                  {i + 1}
                </div>
                <h3 className="font-bold text-[21px] text-center">{l.title}</h3>
                <p className="text-[#1E1E1E] text-center text-[18px]">
                  {l.desc}
                </p>
              </div>
            ))}
          </div>

          <div className="flex justify-center items-center my-20 space-x-10">
            <a href="https://apps.apple.com/mn/app/urtuu/id6446311351">
              <StaticImage
                src="../images/appstore.png"
                alt={"Urtuu App - App Store"}
                placeholder="none"
                height={68}
                quality={100}
              />
            </a>
            <a href="https://play.google.com/store/apps/details?id=mn.urtuu.app">
              <StaticImage
                src="../images/playstore.png"
                alt={"Urtuu App - Play Store"}
                placeholder="none"
                height={68}
                quality={100}
              />
            </a>
          </div>
        </div>

        <div className="text-center mb-10 mt-28">
          <StaticImage src="../images/v2/hunnu.png" alt="" />
        </div>

        <div className="bg-c2 py-[50px]">
          <div className="mx-auto max-w-7xl px-8">
            <div className="items-center lg:hidden grid-cols-1 lg:grid-cols-2 grid-rows-2 w-full mx-auto lg:w-[80%]">
              <div className="overflow-hidden rounded-tl-[150px] s-fkr">
                <StaticImage
                  src="../images/v2/aljaal-tailah-baria-1.png"
                  alt={"Urtuu"}
                  placeholder="none"
                  quality={100}
                  // layout="fixed"
                  // height={400}
                  layout="fullWidth"
                />
              </div>
              <div className="p-10">
                <h1 className="text-[21px] lg:text-[38px] font-bold leading-10 mb-5 uppercase">
                  ХҮННҮ МАССАЖ
                </h1>
                <p className="text-[18px] leading-[40px]">
                  Манай байгууллага нь 2019 оноос үйл ажиллагаагаа эхлэн
                  тогтвортой ажиллаж байна. Бид нэг дор 35 хүн хүлээн авах
                  боломжтой, мэргэжлийн, чадварлаг хамт олон танд дуудлагаар
                  очин үйлчлэхэд бэлэн байна.
                </p>
              </div>
              <div className="order-3 lg:order-4 overflow-hidden rounded-tr-[150px] s-fkr-2">
                <StaticImage
                  src="../images/v2/aljaal-tailah-baria-2.png"
                  alt={"Urtuu"}
                  placeholder="none"
                  quality={100}
                  // layout="fixed"
                  // height={400}
                  layout="fullWidth"
                />
              </div>
              <div className="p-10 order-4 lg:order-3">
                <h1 className="text-[21px] lg:text-[38px] font-bold leading-10 mb-5 uppercase">
                  Бариачид
                </h1>
                <p className="text-[18px] leading-[40px]">
                  5 болон түүнээс дээш жил мэргэжилээрээ тогтвортой ажилласан,
                  өндөр туршлагатай баг хамт олон танд үйлчилж байна.
                </p>
              </div>
            </div>

            <div className="items-center hidden lg:grid grid-cols-1 lg:grid-cols-2 grid-rows-2 w-full mx-auto lg:w-[80%]">
              <div className="overflow-hidden rounded-tl-[150px] s-fkr">
                <StaticImage
                  src="../images/v2/aljaal-tailah-baria-1.png"
                  alt={"Urtuu"}
                  placeholder="none"
                  quality={100}
                  // layout="fixed"
                  // height={400}
                  layout="fullWidth"
                />
              </div>
              <div className="p-10">
                <h1 className="text-[21px] lg:text-[38px] font-bold leading-10 mb-5 uppercase">
                  ХҮННҮ МАССАЖ
                </h1>
                <p className="text-[18px] leading-[40px]">
                  Манай байгууллага нь 2019 оноос үйл ажиллагаагаа эхлэн
                  тогтвортой ажиллаж байна. Бид нэг дор 35 хүн хүлээн авах
                  боломжтой, мэргэжлийн, чадварлаг хамт олон танд дуудлагаар
                  очин үйлчлэхэд бэлэн байна.
                </p>
              </div>
              <div className="p-10 order-4 lg:order-3">
                <h1 className="text-[21px] lg:text-[38px] font-bold leading-10 mb-5 uppercase">
                  Бариачид
                </h1>
                <p className="text-[18px] leading-[40px]">
                  5 болон түүнээс дээш жил мэргэжилээрээ тогтвортой ажилласан,
                  өндөр туршлагатай баг хамт олон танд үйлчилж байна.
                </p>
              </div>
              <div className="order-3 lg:order-4 overflow-hidden rounded-tr-[150px] s-fkr-2">
                <StaticImage
                  src="../images/v2/aljaal-tailah-baria-2.png"
                  alt={"Urtuu"}
                  placeholder="none"
                  quality={100}
                  // layout="fixed"
                  // height={400}
                  layout="fullWidth"
                />
              </div>
            </div>
          </div>
        </div>
        <div className="max-w-7xl mx-auto px-8 mb-20">
          <h1 className="text-[21px] lg:text-[38px] font-bold uppercase text-center my-20">
            Үнийн мэдээлэл
          </h1>

          <div className="grid grid-cols-1 lg:grid-cols-3 w-full justify-center  mx-auto gap-10">
            {priceList?.map((p, i) => (
              <div className="bg-white shadow-xl p-10 flex flex-col items-center border border-gray-100 rounded-xl">
                <StaticImage
                  src="../images/f8.png"
                  alt={"Urtuu"}
                  placeholder="none"
                  quality={100}
                  // layout="fixed"
                  height={85}
                  layout="fixed"
                />
                <div className="w-full pt-10">
                  <span className="text-[18px]">{p.duration}</span>
                  <h3 className="font-bold text-[21px] text-left leading-[30px] my-2">
                    {p.title}
                  </h3>
                  <span className="text-[18px]">{p?.price}</span>
                  <ul className="mt-5 space-y-6">
                    {p?.items?.map((_, i) => (
                      <li
                        className="flex items-center space-x-2 mb-2 text-[18px]"
                        key={`b1-${i}`}
                      >
                        <BsCheck2 size={21} /> <span>{_}</span>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            ))}
          </div>
        </div>
        <div className="max-w-7xl mx-auto px-8">
          <h1 className="text-[21px] lg:text-[38px] font-bold uppercase text-center my-20">
            Үйлчилгээ үзүүлэгчийн баримтлах зарчим
          </h1>
        </div>

        <div className="bg-c2">
          <div className="max-w-7xl mx-auto px-8 mb-20 py-5">
            <h3 className="text-center text-[21px]">
              Алжаал тайлах бариа хийх үйлчилгээ үзүүлэгч нь <br />
              доорх зарчмыг баримтлан ажиллана.
            </h3>
          </div>
        </div>
        <div className="max-w-7xl mx-auto px-8 mb-20 flex justify-center">
          <ul className="text-[18px] pl-8 lg:pl-0  list-disc list-outside  space-y-5 mx-auto inline-block">
            <li>Үйлчлүүлэгчийн хувийн мэдээллийг чанд нууцална.</li>
            <li>
              Шаардлагатай тоног төхөөрөөмжтэйгээ ирж, тав тухтай орчинд
              үйлчилнэ.
            </li>
            <li>
              Үйлчлүүлэгтэйгээ зөв зохистой, ёс зүйтэй харилцахын хамт захиалсан
              цагийн дагуу бүтэн үйлчилгээ үзүүлнэ.
            </li>
            <li>
              Үйлчлүүлэгчтэй урьдчилж хэлэлцэлгүй дур мэдэн шийдвэр гаргахгүй
              байж, үргэлж үнэн зөв мэдээллээр хангана.
            </li>
            <li>Эрүүл мэндийн үзлэгт тогтмол хамрагдана.</li>
            <li>
              Мэдлэг дээшлүүлэх сургалтад хамрагдаж ур чадвараа дээшлүүлэхэд
              анхаарна.
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default Service1Container;
